import React, { Component } from 'react';
import './App.css';
import FluxoDia from './components/FluxoDia'
import SelecaoDia from './components/SelecaoDia';
import Login from './components/Login';
import FechamentoMes from './components/FechamentoMes';
import ErrorBoundary from 'react-error-boundary';
import Util from './services/Util';

const ErroGenerico = (objetoMensagem) => {

  return (
  <div>
    <p><strong>Algum inesperado ocorreu!</strong></p>
    <p>A mensagem recebida foi ...</p>
    <p><strong>Mensagem:</strong> {objetoMensagem.mensagem}</p>
  </div>
  )
};

class App extends Component {

  constructor() {

    super();
    this.state = {
      modo: 'login',
      mensagemErro: ''
    };
  }

  editarDia = (data) => {

    this.setState({
      modo: 'editar_dia',
      data
    });
  }

  gerarRelatorio = (data) => {

    window.open( '/api/fluxo-dia/gerar?data=' + Util.converterDateParaDataISOString(data) + '&api_token=' + localStorage.getItem('api_token'),'_blank');
  }

  gerarRelatorioMes = (mes) => {
    
    let mesIso = String(mes.substring(0,4)).padStart( 4, '0') + '-' + String(mes.substring(5)).padStart( 2, '0') + '-01';
    window.open( '/api/fluxo-mes/gerar-relatorio?mes=' + mesIso + '&api_token=' + localStorage.getItem('api_token'),'_blank');
  }

  selecaoDia = () => {

    this.setState({
      modo: 'selecao_dia'
    });
  }

  login = () => {

    this.setState({
      modo: 'login'
    });    
  }

  exibeErro = (mensagem) => {

    console.log( mensagem);
    this.setState({
      modo: 'erro',
      mensagemErro: mensagem
    });
  }

  fecharMes = (ano,mes) => {

    console.group('ano,mes');
    console.log(ano);
    console.log(mes);
    console.groupEnd();
    this.setState({

      modo: 'fechar_mes',
      ano,
      mes
    });
  }
  
  myErrorHandler = (error, componentStack) => {
    // Do something with the error
    // E.g. log to an error logging client here
    console.error(`error = ${error} - componentStack = ${componentStack}`);
  };
  
  MyFallbackComponent = ({ componentStack, error }) => (
    <div>
      <p><strong>Oops! An error occured!</strong></p>
      <p>Here’s what we know…</p>
      <p><strong>Error:</strong> {error.toString()}</p>
      <p><strong>Stacktrace:</strong> {componentStack}</p>
    </div>
  );

  render() {

    if( this.state.modo === 'login') {

      return (

        <ErrorBoundary onError={this.myErrorHandler} FallbackComponent={this.MyFallbackComponent}>
          <Login 
            onEntrar={this.selecaoDia} 
            onErro={this.exibeErro} />
        </ErrorBoundary>
      )
    } else if( this.state.modo === 'selecao_dia') {

      return (
        <ErrorBoundary onError={this.myErrorHandler} FallbackComponent={this.MyFallbackComponent}>
          <SelecaoDia 
            onEditarDia={this.editarDia}
            onFecharMes={this.fecharMes} 
            gerarRelatorio={this.gerarRelatorio} 
            gerarRelatorioMes={this.gerarRelatorioMes} />
        </ErrorBoundary>
        )
    } else if( this.state.modo === 'editar_dia') {

      return (
      <ErrorBoundary onError={this.myErrorHandler} FallbackComponent={this.MyFallbackComponent}> 
        <FluxoDia 
          data={this.state.data}
          onSelecaoDia={ this.selecaoDia } 
          onErro={this.exibeErro} 
          login={this.login} />
      </ErrorBoundary>
      );
    } else if( this.state.modo === 'fechar_mes') {

      return (
        <ErrorBoundary onError={this.myErrorHandler} FallbackComponent={this.MyFallbackComponent}> 
          <FechamentoMes 
            ano={this.state.ano}
            mes={this.state.mes}
            onErro={this.exibeErro} 
            onSelecaoDia={ this.selecaoDia } 
            login={this.login} />
        </ErrorBoundary>
        );
    } else {

      return (
        <ErroGenerico mensagem={this.state.mensagemErro}/>
      )
    }
  }
}

export default App;
