import React, { Component } from 'react';
import SecaoDia from '../SecaoDia';
import Util from '../../services/Util';

export default class SecaoMes extends Component {

    constructor(props) {

        super(props);
        this.state = {
            ano: props.mes.substring(0, 4),
            mes: parseInt( props.mes.substring(5))
        }
    }

    formatarTitulo = () => {

        return Util.converterMesParaString(this.state.mes) + ' de ' + this.state.ano;
    }

    render() {

        return ( 
            
            <div className="card">
                <div className="card-header" id={"heading"+this.props.mes}>
                    <h2 className="mb-0">
                        <button className="btn btn-link" type="button" data-toggle="collapse" 
                        data-target={"#collapse"+this.props.mes} aria-expanded="true" aria-controls={"collapse"+this.props.mes}>
                        {this.formatarTitulo()}
                        </button>
                        <button className="btn btn-outline-success btn-sm" type="button" 
                            onClick={() => this.props.onFecharMes(this.state.ano,this.state.mes)}>Fechar</button>
                        <button type="button" className="btn btn-link btn-sm" 
                            // disabled={!this.state.feria}
                            onClick={(evt=> this.props.gerarRelatorioMes(this.props.mes))}>Relatório</button>                    </h2>
                </div>

                <div id={"collapse"+this.props.mes} className="collapse show" aria-labelledby={"heading"+this.props.mes} data-parent="#accordionExample">

                    <div className="card-body">

                        <div className="row row-cols-1 row-cols-md-4">

                            {this.props.dias.map( (value,index) => 
                                
                                <SecaoDia 
                                    key={index} 
                                    dia={value}
                                    resumoPorDia={this.props.resumoPorDia}
                                    abrir={this.props.abrir} 
                                    gerarRelatorio={this.props.gerarRelatorio}
                                    />  
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}