import React, { Component } from 'react';
import Util from '../../services/Util';

export default class SecaoDia extends Component {

    constructor() {

        super();
        this.state = {

            feria: null,
            despesa: null,
            feriado: 0
        }
    }

    componentDidMount() {

        let dia = Util.converterDateParaDataISOString(this.props.dia);
        console.log(dia);
        let resumo = this.props.resumoPorDia.filter( resumo => resumo.dia === dia);
        console.log( resumo);
        if( resumo.length === 1) {

            this.setState({ feria: resumo[0].feria, despesa: resumo[0].despesa, feriado: resumo[0].feriado });
        }
    }

    exibeDados = () => {

        return (
            <span>
                Féria: {Util.formatarNumberEmStringMoeda(this.state.feria)}
                <br/>
                Despesa: {Util.formatarNumberEmStringMoeda(this.state.despesa)}
            </span>
        );
    }

    exibeAPreencher = () => {

        if( (new Date(this.props.dia).getDay() === 0) ) { // se for domingo

            return (
                <span>
                    DOMINGO
                    <br/>
                    &nbsp;
                </span>
            );
        } else if( this.state.feriado === 1) {

            return (
                <span>
                    FERIADO
                    <br/>
                    &nbsp;
                </span>
            );
        }


        return (

            <span>
                <span className="badge badge-danger">Não preenchido</span>
                <br/>&nbsp;

            </span>
        );
    }

    render() {

        return (

            <div className="col mb-4">
                <div className="card" style={{width: "14rem"}}>
                    <div className="card-body">
                        <h5 className="card-title">{Util.converterDateParaString(this.props.dia)}</h5>
                        <p className="card-text">
                            {this.state.feria?this.exibeDados():this.exibeAPreencher()}
                        </p>
                        <button className="btn btn-primary btn-lg" onClick={(evt) => this.props.abrir( this.props.dia)}>Editar</button>
                        &nbsp;
                        <button type="button" className="btn btn-link btn-sm" 
                            disabled={!this.state.feria}
                            onClick={(evt=> this.props.gerarRelatorio(this.props.dia))}>Relatório</button>
                    </div>
                </div>
            </div>
        );
    }
}