import React, { Component } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Util from '../../services/Util';

export default class Despesa extends Component {

    constructor() {

        super();

        this.state = {

            complemento: '',
            optionTipoDespesa: null,
            optionSubTipoDespesa: null,
            optionsTipoDespesa: [ ],
            optionsSubTipoDespesa: [ ],
            valorString: ''
        }
        this.refTipoDespesa = React.createRef();
    }

    componentDidMount() {

        console.log( 'tiposDespesa por parametro apos o componentDidMount em despesas');
        console.log( this.props.tiposDespesa);

        let optionsTipoDespesa = this.props.tiposDespesa.filter( item => /^2\.\d+$/g.test(item.codigo)).map( item => { 
            return {'value': item.codigo, 'label': item.nome};
        });

        let optionTipoDespesa = null;
        let optionSubTipoDespesa = null;
        let optionsSubTipoDespesa = [];
        if( this.props.despesa.codigoDespesa) {
            
            let codigoPai = Util.obterCodigoTipoDespesaPai( this.props.despesa.codigoDespesa);
            optionTipoDespesa = optionsTipoDespesa.filter( item => item.value === codigoPai)[0];
            optionSubTipoDespesa = {'value': this.props.despesa.codigoDespesa, 'label': this.props.despesa.nomeTipoDespesa};
            optionsSubTipoDespesa = this.obterOptionsSubTipoDespesa( codigoPai);
        }

        this.setState({

            complemento: this.props.despesa.complemento || '',
            valorString: Util.formatarNumberEmStringMoeda(this.props.despesa.valor),
            optionsTipoDespesa,
            optionsSubTipoDespesa,
            optionTipoDespesa,
            optionSubTipoDespesa
        });
        this.refTipoDespesa.current.focus();
    }

    filtrarSubtipo = (option) => {

        const codigoTipo = option.value;
        if( codigoTipo) {

            let filtrado = this.obterOptionsSubTipoDespesa( codigoTipo);
            let optionTipoDespesa = this.state.optionsTipoDespesa.filter( item => item.value === codigoTipo)[0];
            this.setState({

                optionTipoDespesa,
                optionSubTipoDespesa: null,
                optionsSubTipoDespesa: filtrado
            });
        }
    }

    obterOptionsSubTipoDespesa = (codigoTipoDespesa) => {

        const tipo = codigoTipoDespesa.replace('.', '\\.');
        const regstr = `^${tipo}\\.\\d+$`;
        let re = new RegExp( regstr);
        let filtrado = this.props.tiposDespesa.filter( item => re.test(item.codigo)).map( item => { 
            return {'value': item.codigo, 'label': item.nome};
        });
        return filtrado;
    }

    onCreateSubTipoDespesa = (value) => {

        if( !this.state.optionTipoDespesa ) {

            confirmAlert({title: 'Aviso', message: 'Selecione antes um tipo.', buttons: [ {label: 'Ok'}] } );
            return;
        }
        confirmAlert({
            title: 'Confirmação',
            message: `Quer realmente criar o subtipo ${value}?`,
            buttons: [
              {
                label: 'Sim',
                onClick: () => {
                    
                    let ultimoValueOptionSubtipo;
                    if( this.state.optionsSubTipoDespesa.length === 0) {

                        ultimoValueOptionSubtipo = `${this.state.optionTipoDespesa.value}.0`;
                    } else {

                        ultimoValueOptionSubtipo = this.state.optionsSubTipoDespesa[ this.state.optionsSubTipoDespesa.length-1].value;
                    }
                    console.log( ultimoValueOptionSubtipo);
                    const regStr = `^${this.state.optionTipoDespesa.value}\\.(\\d+)$`;
                    console.log( regStr);
                    const re = new RegExp( regStr);
                    const ultimoCodigoAtual = parseInt( re.exec( ultimoValueOptionSubtipo)[1]);
                    const novoCodigo = `${this.state.optionTipoDespesa.value}.${ultimoCodigoAtual+1}`;

                    const novoTipoDespesa = { 'codigo': novoCodigo, 'nome': value};
                    console.log( novoTipoDespesa);
                    console.log( 'em despesa de props.tiposDespesa');
                    console.log( this.props.tiposDespesa);
                    fetch('/api/tipo-despesa?api_token=' + localStorage.getItem('api_token'),{ 
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify( novoTipoDespesa) })
                    .then( (response) => {

                        console.log( `response do servidor`);
                        console.log( response);
                        if( response.status !== 200) {

                            throw new Error( response.statusText);
                        }
                        this.props.adicionarTipoDespesa( novoTipoDespesa);
                        let novoOption = { 'value': novoCodigo, 'label': value };
                        this.setState({
                            
                            optionsSubTipoDespesa: [ ...this.state.optionsSubTipoDespesa, novoOption],
                            optionSubTipoDespesa: novoOption
                        });
                        this.props.despesa.codigoDespesa = novoCodigo;
                        this.props.despesa.nomeTipoDespesa = value;
                    })
                    .catch( (error) => {
                      
                        this.props.onErro(`Erro ao inserir tipo de despesa. codigo=${novoCodigo} - nome=${value} - error=${error}`);
                    }); 
                }
              },
              {
                label: 'Não',
              }
            ]
          });        
    }

    formatCreateLabel = (value) => `Criar ${value}`

    render() {
       
        return (

            <div className="form-group row">
                <div className="col-sm-2">
                    <Select id={'tipoDespesa'+this.props.despesa.key} placeholder="Tipo" 
                        options={this.state.optionsTipoDespesa}
                        value={this.state.optionTipoDespesa} 
                        onChange={this.filtrarSubtipo}
                        ref={this.refTipoDespesa} />
                </div>
                <div className="col-sm-2">
                    <CreatableSelect 
                        options={this.state.optionsSubTipoDespesa} 
                        value={this.state.optionSubTipoDespesa}
                        placeholder="Subtipo" 
                        onCreateOption={this.onCreateSubTipoDespesa}
                        formatCreateLabel={this.formatCreateLabel}
                        onChange={ option => {
                                this.props.despesa.codigoDespesa = option.value
                                this.props.despesa.nomeTipoDespesa = option.label;
                                this.setState({
                                    optionSubTipoDespesa: option
                                });
                            }
                        } />
                </div>
                <div className="col-sm-2">
                    <input type="text" className="form-control" id="complemento" placeholder="Complemento"
                        value={this.state.complemento}
                        onChange={evt => { 
                            this.props.despesa.complemento = evt.target.value;
                            this.setState({ complemento: evt.target.value});
                        }} />
                </div>
                <div className="col-sm-2">
                    <input type="text" className="form-control" id="valor" placeholder="Valor" autoComplete="off"
                        value={this.state.valorString}
                        onChange={evt => { 
                            this.props.despesa.valor = Util.converterStringParaMoeda(evt.target.value);
                            this.setState( { valorString: evt.target.value })
                        }} />                        
                </div>
                <div className="col-sm-2">
                    <button className="btn btn-outline-dark" id="excluirDespesa" onClick={this.props.onExcluir}>-</button>
                </div>
            </div>
        )
    }
}