import React, { Component } from 'react'
import Despesa from '../Despesa'
import Cartao from '../Cartao'
import Deposito from '../Deposito'
import PagamentoCheque from '../PagamentoCheque'
import Util from '../../services/Util';
import * as yup from 'yup';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const schema = yup.object().shape({
    dia: yup.string().required(),
    dinheiro: yup.number().required().label('Valor do dinheiro incorreto'),
    cheque: yup.number().required().label('Valor do cheque incorreto'),
    despesas: yup.array().of(yup.object().shape({
        codigoDespesa: yup.string().required().label('Subtipo de despesa da seção Despesas incorreto'),
        valor: yup.number().required().positive().label('Valor da seção Despesas incorreto')
    })),
    cartoes: yup.array().of(yup.object().shape({
        idCartao: yup.number().integer().required().positive().label('Bandeira da seção Cartões incorreta'),
        feria: yup.number().required().positive().label('Féria da seção Cartões incorreta'),
        despesa: yup.number().required().label('Despesa da seção Cartões incorreta'),
    })),
    depositos: yup.array().of(yup.object().shape({
        idBanco: yup.number().integer().required().positive().label('Banco da seção Depósitos/Cobranças incorreto'),
        feria: yup.number().required().label('Féria da seção Depósitos/Cobranças incorreta'),
        despesa: yup.number().required().label('Despesa da seção Depósitos/Cobranças incorreta')
    })),
    pagamentosCheque: yup.array().of(yup.object().shape({
        idBanco: yup.number().integer().required().positive().label('Banco da seção Pagamentos incorreto'),
        cheque: yup.string().required().label('Cheque da seção Pagamentos incorreto'),
        creditados: yup.array().of(yup.object().shape({
            codigoDespesa: yup.string().required().label('Subtipo de despesa da seção Pagamentos incorreto'),
            valor: yup.number().required().positive().label('Valor da seção Pagamentos incorreto')
        })).min(1)
    })),

});

export default class FluxoDia extends Component {

    constructor() {
        
        super()
        this.state = {
            carregando: true,
            dinheiroString: '',
            chequeString: '0,00',
            feriadoBoolean: false,
            fluxoDia: {
                dia: '',
                feriado: 0,
                dinheiro: null,
                cheque: 0.0,
                despesas: [ ],
                cartoes: [ ],
                depositos: [ ],
                pagamentosCheque: [ ]
            },
            tiposDespesa: [],
            menuCartao: [],
            menuBanco: []
        }
    }

    componentDidMount() {
        
        console.group('data recebida em fluxo do dia');
        console.log( this.props.data);
        console.log( Util.converterDateParaDataISOString( this.props.data));
        console.groupEnd();
        let promiseTipoDespesa = fetch('/api/tipo-despesa?api_token=' + localStorage.getItem('api_token'))
            .then( response => {
                console.log( response);
                if( response.status !== 200) {

                    throw new Error( response.statusText);
                }
                return response.json()
            })
            .then( tiposDespesa => {

                console.log( tiposDespesa);
                this.setState({
                    tiposDespesa
                });
            })
            .catch(erro => {

                console.log( erro.message);
                if( erro.message === 'Unauthorized') {

                    console.log('erro de autenticacao');
                    this.props.login();
                } else {

                    this.props.onErro(`Erro ao obter 'tiposDespesa' da base de dados. ${erro}`);
                }
            });
        let promiseCartao = fetch('/api/cartao?api_token=' + localStorage.getItem('api_token'))
            .then( response => {
                console.log( response);
                if( response.status !== 200) {

                    throw new Error( response.statusText);
                }
                return response.json()
            })
            .then( menuCartao => {

                console.log( menuCartao);
                this.setState({
                    menuCartao
                });
            })
            .catch(erro => {

                if( erro.message === 'Unauthorized') {

                    console.log('erro de autenticacao');
                    this.props.login();
                } else {

                    this.props.onErro(`Erro ao obter 'cartoes' da base de dados. ${erro}`);
                }
            });
        let promiseBanco = fetch('/api/banco?api_token=' + localStorage.getItem('api_token'))
            .then( response => {
                console.log( response);
                if( response.status !== 200) {

                    throw new Error( response.statusText);
                }
                return response.json()
            })
            .then( menuBanco => {

                console.log( menuBanco);
                this.setState({
                    menuBanco
                });
            })
            .catch(erro => {

                if( erro.message === 'Unauthorized') {

                    console.log('erro de autenticacao');
                    this.props.login();
                } else {

                    this.props.onErro(`Erro ao obter 'bancos' da base de dados. ${erro}`);
                }
            });
        let data = Util.converterDateParaDataISOString( this.props.data);
        Promise.all([ promiseTipoDespesa, promiseCartao, promiseBanco]).then( () => {

            fetch('/api/fluxo-dia?data=' + data + '&api_token=' + localStorage.getItem('api_token'))
            .then( response => {
                console.log( response);
                if( response.status !== 200) {

                    throw new Error( response.statusText);
                }
                return response.json()
            })
            .then( arrayFluxoDia => {

                console.group('arrayFluxoDia');
                console.log( arrayFluxoDia);
                console.groupEnd();
                if( arrayFluxoDia.length !== 1 ) { 

                    this.setState({
                        
                        carregando: false,
                        fluxoDia: {
                            ...this.state.fluxoDia,
                            dia: data
                        }
                    });
                } else {

                    this.setState({
                       
                        feriadoBoolean: (arrayFluxoDia[0].feriado === 1),
                        carregando: false,
                        dinheiroString: Util.formatarNumberEmStringMoeda(arrayFluxoDia[0].dinheiro),
                        chequeString: Util.formatarNumberEmStringMoeda(arrayFluxoDia[0].cheque),
                        fluxoDia: arrayFluxoDia[0]
                    });
                }
    
            })
            .catch(erro => {

                console.log( erro.message);
                if( erro.message === 'Unauthorized') {

                    console.log('erro de autenticacao');
                    this.props.login();
                } else {

                    this.props.onErro(`Erro ao obter 'tiposDespesa' da base de dados. ${erro}`);
                }
            });
        });
    }

    adicionarTipoDespesa = (novo) => {

        console.log( 'vou mostrar o novo');
        console.log( novo);
        let atual = this.state.tiposDespesa;
        const novaLista = [ ...atual, novo];
        console.log('novaLista:');
        console.log( novaLista);
        this.setState({

            tiposDespesa: novaLista
        });
    }

    excluirDespesa = (keyParaExcluir) => {

        let despesas = this.state.fluxoDia.despesas.filter( (despesa) => despesa.key !== keyParaExcluir);
        this.setState({

            fluxoDia: {
                ...this.state.fluxoDia,
                despesas
            }
        });
    };

    addDespesa = (evt) => {

        evt.preventDefault()
        console.log( 'tiposDespesa nesse ponto');
        console.log( this.state.tiposDespesa);
        const despesasAntigas = this.state.fluxoDia.despesas
        const keyNovo = String(Math.max.apply(Math, this.state.fluxoDia.despesas.map(function(despesa) { return parseInt(despesa.key)+1; }))) | '0';
        console.log( 'keyNovo atribuido');
        console.log( keyNovo);
        const novaDespesa = { key: keyNovo }
        this.setState( {
            fluxoDia: {
                ...this.state.fluxoDia,
                despesas: [ ...despesasAntigas, novaDespesa]
            }
        });
    }

    excluirCartao = (keyParaExcluir) => {

        let cartoes = this.state.fluxoDia.cartoes.filter( (cartao) => cartao.key !== keyParaExcluir);
        this.setState({

            fluxoDia: {
                ...this.state.fluxoDia,
                cartoes
            }
        });
    };

    addCartao = (evt) => {

        evt.preventDefault();
        const keyNovo = String(Math.max.apply(Math, this.state.fluxoDia.cartoes.map(function(cartao) { return parseInt(cartao.key)+1; }))) | '0';
        const novoCartao = {

            key: keyNovo
        };        
        const cartoesAntigos = this.state.fluxoDia.cartoes
        this.setState( {
            
            fluxoDia: {
                ...this.state.fluxoDia,
                cartoes: [ ...cartoesAntigos, novoCartao]
            }
        });
    }

    excluirDeposito = (keyParaExcluir) => {

        let depositos = this.state.fluxoDia.depositos.filter( (deposito) => deposito.key !== keyParaExcluir);
        this.setState({
            fluxoDia: {
                ...this.state.fluxoDia,
                depositos
            }
        });
    };
    
    addDeposito = (evt) => {

        evt.preventDefault();
        const keyNovo = String(Math.max.apply(Math, this.state.fluxoDia.depositos.map(function(deposito) { return parseInt(deposito.key)+1; }))) | '0';
        const novoDeposito = {

            key: keyNovo
        };   
        const depositosAntigos = this.state.fluxoDia.depositos
        this.setState( {
            fluxoDia: {
                ...this.state.fluxoDia,
                depositos: [ ...depositosAntigos, novoDeposito]
            }
        });
    }

    excluirPagamentoCheque = (keyParaExcluir) => {

        let pagamentosCheque = this.state.fluxoDia.pagamentosCheque.filter( (pagamentoCheque) => pagamentoCheque.key !== keyParaExcluir);
        this.setState({
            fluxoDia: {
                ...this.state.fluxoDia,
                pagamentosCheque
            }
        });
    };
        
    addPagamentoCheque = (evt) => {

        evt.preventDefault();
        const keyNovo = String(Math.max.apply(Math, this.state.fluxoDia.pagamentosCheque.map(function(pagamentoCheque) { return parseInt(pagamentoCheque.key)+1; }))) | '0';
        const novoPagamentoCheque = {

            key: keyNovo,
            creditados: [ { key: 0, codigoDespesa: null, valor: null } ]
        }
        const pagamentosChequeAntigos = this.state.fluxoDia.pagamentosCheque
        this.setState( {
            fluxoDia: {
                ...this.state.fluxoDia,
                pagamentosCheque: [ ...pagamentosChequeAntigos, novoPagamentoCheque]
            }
        });
    }

    salvar = (evt) => {

        evt.preventDefault();
        console.log( JSON.stringify(this.state.fluxoDia));

        schema.validate(this.state.fluxoDia)
            .then((value) => {

                fetch('/api/fluxo-dia?api_token=' + localStorage.getItem('api_token'), { 
                    method: 'POST', 
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify( value) 
                }).then( (response) => {
        
                    console.log( `response do servidor`);
                    console.log( response);
                    if( response.status !== 200) {
        
                        throw new Error( response.statusText);
                    }
                    window.open( '/api/fluxo-dia/gerar?data=' + this.state.fluxoDia.dia + '&api_token=' + localStorage.getItem('api_token'),'_blank');
                    this.props.onSelecaoDia();
            
                }).catch( (error) => {
                
                    this.props.onErro(`Erro ao inserir fluxo do dia. error=${error}`);
                });
            })            
            .catch( (err) => {
                console.error( err);
                let msg = err.params.label || 'Erro nos dados digitados';
                confirmAlert({title: 'Erro', message: msg, buttons: [ {label: 'Ok'}] } );
            });
    }

    render() {

        return (
            <div className="container">
                <h1>{Util.converterDateParaString( this.props.data)}</h1>
                <div hidden={this.state.carregando}>
                    <form>
                        <div className="col-sm-4 custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="feriado"
                                checked={this.state.feriadoBoolean}
                                onChange={ evt => {
                                    console.log(evt.target.checked);
                                    if( evt.target.checked) {

                                        this.setState({ 
                                            feriadoBoolean: true,
                                            fluxoDia: {
                                            ...this.state.fluxoDia,
                                            feriado: 1,
                                            dinheiro: 0.0,
                                            cheque: 0.0
                                        }});
                                    } else {

                                        this.setState({ 
                                            feriadoBoolean: false,
                                            fluxoDia: {
                                            ...this.state.fluxoDia,
                                            feriado: 0
                                        }});
                                    }
                                    
                                }}/>
                            <label className="custom-control-label" htmlFor="feriado">Feriado</label>
                        </div>
                        <div hidden={this.state.feriadoBoolean}>

                            <fieldset>
                                <legend>Receitas</legend>
                                <div className="form-group row">
                                    <label htmlFor="dinheiro" className="col-sm-2 col-form-label">Dinheiro</label>
                                    <div className="col-sm-2">
                                        <input type="text" className="form-control" id="dinheiro" autoComplete="off" 
                                            value={this.state.dinheiroString}
                                            onChange={ (evt) => 
                                                this.setState( { 
                                                    fluxoDia: {
                                                        ...this.state.fluxoDia,
                                                        dinheiro: Util.converterStringParaMoeda(evt.target.value)
                                                    },
                                                    dinheiroString: evt.target.value
                                                })
                                            } />
                                    </div>
                                </div>
                                {/* <div className="form-group row">
                                    <label htmlFor="cheque" className="col-sm-2 col-form-label">Cheque</label>
                                    <div className="col-sm-2">
                                        <input type="text" className="form-control" id="cheque" autoComplete="off" 
                                            value={this.state.chequeString}
                                            onChange={ (evt) => 
                                                this.setState( { fluxoDia: {
                                                        ...this.state.fluxoDia,
                                                        cheque: Util.converterStringParaMoeda(evt.target.value)
                                                    },
                                                    chequeString: evt.target.value
                                                })
                                            }/>
                                    </div>
                                </div> */}
                                <div className="form-group row">
                                    <label htmlFor="tipoDespesa" className="col-sm-2 col-form-label">Despesas</label>
                                    <div className="col-sm-10">
                                        <div id="despesas">
                                            {
                                                this.state.fluxoDia.despesas.map( despesa =>

                                                    <Despesa key={despesa.key}
                                                        despesa={despesa}
                                                        tiposDespesa={this.state.tiposDespesa}
                                                        adicionarTipoDespesa={this.adicionarTipoDespesa}
                                                        onErro={this.props.onErro} 
                                                        onExcluir={(event) => { 
                                                            event.preventDefault();
                                                            this.excluirDespesa(despesa.key);
                                                        }} />
                                                )
                                            }
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-2">
                                                <button className="btn btn-outline-dark" id="addDespesa" onClick={this.addDespesa}>+</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-2 col-form-label">Cartões</label>
                                    <div className="col-sm-10">
                                        <div id="cartoes">
                                            {
                                                this.state.fluxoDia.cartoes.map( cartao =>
                                                    
                                                    <Cartao key={cartao.key}
                                                        cartao={cartao}
                                                        menuCartao={this.state.menuCartao} 
                                                        onErro={this.props.onErro} 
                                                        onExcluir={(event) => { 
                                                            event.preventDefault();
                                                            this.excluirCartao(cartao.key);
                                                        }
                                                        } />
                                                )
                                            }
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-2">
                                                <button className="btn btn-outline-dark" id="addCartao" onClick={this.addCartao}>+</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-2 col-form-label">Depósitos/Cobranças</label>
                                    <div className="col-sm-10">
                                        <div id="depositos">
                                        {
                                            this.state.fluxoDia.depositos.map( deposito =>
                                            
                                                <Deposito key={deposito.key} 
                                                    deposito={deposito}
                                                    menuBanco={this.state.menuBanco}
                                                    onErro={this.props.onErro} 
                                                    onExcluir={(event) => { 
                                                        event.preventDefault();
                                                        this.excluirDeposito(deposito.key);
                                                    }
                                                    } />
                                            )
                                        }
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-2">
                                                <button className="btn btn-outline-dark" id="addDeposito" onClick={this.addDeposito}>+</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset>
                                <legend>Pagamentos</legend>
                                <div className="form-group row">
                                    <label htmlFor="tipoDespesa" className="col-sm-2 col-form-label">Cheques</label>
                                    <div className="col-sm-10">
                                        <div id="despesasCheque">
                                        {
                                            this.state.fluxoDia.pagamentosCheque.map( pagamentoCheque => 
                                                <PagamentoCheque key={pagamentoCheque.key}
                                                    pagamentoCheque={pagamentoCheque}
                                                    menuBanco={this.state.menuBanco} 
                                                    tiposDespesa={this.state.tiposDespesa}
                                                    adicionarTipoDespesa={this.adicionarTipoDespesa}
                                                    onErro={this.props.onErro} 
                                                    onExcluir={(event) => { this.excluirPagamentoCheque(pagamentoCheque.key)}} />
                                            )
                                        }
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-2">
                                                <button className="btn btn-outline-dark" id="addPagamentoCheque" onClick={this.addPagamentoCheque}>+</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                        </div>

                        <div className="row">
                            <button className="btn btn-primary" onClick={this.salvar}>Salvar</button>
                            <button className="btn btn-link" onClick={this.props.onSelecaoDia}>Voltar</button>
                        </div>
                    </form>
                </div>
                <div hidden={!this.state.carregando}>
                    <img src="progress.gif" alt="carregando..."/>
                </div>
            </div>
        );
    }
}