import React, { Component } from 'react';
import SecaoMes from '../SecaoMes';
import Select from 'react-select';

export default class SelecaoDia extends Component {

    constructor() {

        super()
        this.state = {
  
          carregando: true,
          meses: [],
          resumoPorDia: [],
          optionsQtdeMeses: [
            { 'value': 2, 'label': '2 meses'},
            { 'value': 6, 'label': '6 meses'},
            { 'value': 12, 'label': '12 meses'},
            { 'value': 24, 'label': '24 meses'},
            { 'value': 48, 'label': '48 meses'}],
          optionQtdeMeses: { 'value': 2, 'label': '2 meses'},
          showFiltro: false
        };
    }

    componentDidMount() {


      this.filtrarQtdeMeses( this.state.optionQtdeMeses );
    }

    abrir = (data) => {

        this.props.onEditarDia( data);
    };

    filtrarQtdeMeses = (option) => {

      this.setState( { optionQtdeMeses: option });

      document.querySelector("#navbarToggleExternalContent").className = 'collapse';
      let ultimoMes = new Date();
      ultimoMes.setMonth( ultimoMes.getMonth() - (option.value - 1));
      console.log( 'ultimo mes = ' + ultimoMes);
      let ultimoDia = new Date( ultimoMes.getFullYear(), ultimoMes.getMonth(), 1);
      console.log( 'ultimo dia = ' + ultimoDia);
      
      let diaAtual = new Date();
      let meses = [];
      while( diaAtual > ultimoDia) {
        
        console.log( diaAtual);
        let mes = diaAtual.getFullYear() + '-' + (diaAtual.getMonth()+1);
        if( !meses[mes]) {

          meses[mes] = [];
        } 
        meses[mes].push( new Date( diaAtual.getTime()));

        diaAtual.setDate( diaAtual.getDate() - 1);
      }
      fetch('/api/fluxo-dia/resumo-por-dia?qtdeMeses=' + option.value + '&api_token=' + localStorage.getItem('api_token'))
            .then( response => {
                //console.log( response);
                if( response.status !== 200) {

                    throw new Error( response.statusText);
                }
                return response.json()
            })
            .then( resumoPorDia => {

                //console.log( resumoPorDia);
                this.setState({
                    carregando: false,
                    resumoPorDia,
                    meses
                });
            })
            .catch(erro => {

                console.log( erro.message);
                if( erro.message === 'Unauthorized') {

                    console.log('erro de autenticacao');
                    this.props.login();
                } else {

                    this.props.onErro(`Erro ao ler resumo dos dias da base de dados. ${erro}`);
                }
            });
    };

    render() {

        return (
          <div>

            <div className="pos-f-t">
              <nav className="navbar navbar-dark bg-dark">
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
                <h5 className="text-white h4">Filtro: {this.state.optionQtdeMeses['label']}</h5>
              </nav>
              <div className="collapse" id="navbarToggleExternalContent">
                <div className="bg-dark p-4">
                  <h5 className="text-white h4">Selecione o filtro</h5>
                  <Select id="filtroQtdeMeses"
                    options={this.state.optionsQtdeMeses}
                    value={this.state.optionQtdeMeses}
                    onChange={this.filtrarQtdeMeses} />
                </div>
              </div>
            </div>

            <div className="container">
              <div hidden={this.state.carregando}>
                <div className="accordion" id="accordionExample">

                  {Object.keys(this.state.meses).map((mes) =>
                    <SecaoMes key={mes}
                      mes={mes}
                      resumoPorDia={this.state.resumoPorDia}
                      dias={this.state.meses[mes]}
                      abrir={this.abrir}
                      gerarRelatorio={this.props.gerarRelatorio}
                      gerarRelatorioMes={this.props.gerarRelatorioMes}
                      onFecharMes={this.props.onFecharMes} />
                  )}
                </div>
              </div>
              <div hidden={!this.state.carregando}>
                <img src="progress.gif" alt="carregando..." />
              </div>
            </div>

          </div>
        )
    }
}
