import React, { Component } from 'react';
import Util from '../../services/Util';
import Select from 'react-select';

export default class Deposito extends Component {

    constructor() {

        super();
        this.state = {
            
            optionsBanco: [],
            optionBanco: null,
            feriaString: '',
            despesaString: '',
            complemento: ''
        };
        this.refBanco = React.createRef();
    }

    componentDidMount() {

        let optionsBanco = this.props.menuBanco.map( banco => { return {value: banco.id, label: banco.nome} });
        let optionBanco = null;
        if( this.props.deposito.id) {

            optionBanco = optionsBanco.filter( item => item.value === this.props.deposito.idBanco)[0];
        }
        this.setState( {

            optionsBanco,
            optionBanco,
            feriaString: Util.formatarNumberEmStringMoeda(this.props.deposito.feria),
            despesaString: Util.formatarNumberEmStringMoeda(this.props.deposito.despesa),
            complemento: this.props.deposito.complemento || ''
        });
        this.refBanco.current.focus();
    }

    render() {

        return (

            <div className="form-group row">
                <div className="col-sm-2">
                    <Select id="banco" placeholder="Banco"
                        options={this.state.optionsBanco} 
                        value={this.state.optionBanco}
                        onChange={(value) => {
                            this.props.deposito.idBanco = value.value;
                            this.props.deposito.nomeBanco = value.label;
                            this.setState({ optionBanco: value });
                        }} 
                        ref={this.refBanco} />
                </div>
                <div className="col-sm-2">
                    <input type="text" className="form-control" id="receitaDeposito" placeholder="Féria" autoComplete="off"  
                        value={this.state.feriaString}
                        onChange={ (evt) => {
                            this.props.deposito.feria = Util.converterStringParaMoeda(evt.target.value);
                            this.setState({ feriaString: evt.target.value});
                        }} />
                </div>
                <div className="col-sm-2">
                    <input type="text" className="form-control" id="despesaDeposito" placeholder="Despesa" autoComplete="off" 
                        value={this.state.despesaString}
                        onChange={ (evt) => {
                            this.props.deposito.despesa = Util.converterStringParaMoeda(evt.target.value);
                            this.setState({despesaString: evt.target.value});
                        }} />
                </div>
                <div className="col-sm-2">
                    <input type="text" className="form-control" id="complemento" placeholder="Complemento"
                        value={this.state.complemento}
                        onChange={evt => { 
                            this.props.deposito.complemento = evt.target.value;
                            this.setState({ complemento: evt.target.value});
                        }} />
                </div>
                <div className="col-sm-2">
                    <button className="btn btn-outline-dark" id="excluirDeposito" onClick={this.props.onExcluir}>-</button>
                </div>
            </div>
        )
    }
}