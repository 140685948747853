import React, { Component } from 'react';
import Util from '../../services/Util';
import Select from 'react-select';

export default class Cartao extends Component {

    constructor() {

        super();
        this.state = {

            optionsCartao: [],
            optionCartao: null,
            feria: '',
            despesa: ''
        }
        this.refCartao = React.createRef();
    }

    componentDidMount() {

        let optionsCartao = this.props.menuCartao.map( cartao => { return {value: cartao.id, label: cartao.nome} });
        let optionCartao = null;
        if( this.props.cartao.id) {

            optionCartao = optionsCartao.filter( item => item.value === this.props.cartao.idCartao)[0];
        }
        this.setState( {

            optionsCartao,
            optionCartao,
            feria: Util.formatarNumberEmStringMoeda(this.props.cartao.feria),
            despesa: Util.formatarNumberEmStringMoeda(this.props.cartao.despesa)
        });
        this.refCartao.current.focus();
    }

    render() {

        return (

            <div className="form-group row">
                <div className="col-sm-2">
                    <Select id="cartao" placeholder="Bandeira"
                        options={this.state.optionsCartao}
                        value={this.state.optionCartao}
                        onChange={(value) => {
                            this.props.cartao.idCartao = value.value;
                            this.props.cartao.nomeCartao = value.label;
                            this.setState({ optionCartao: value });
                        }} 
                        ref={this.refCartao} />
                </div>
                <div className="col-sm-2">
                    <input type="text" className="form-control" id="receitaCartao" placeholder="Féria" autoComplete="off" 
                        value={this.state.feria}
                        onChange={ (evt) => { 
                            this.props.cartao.feria = Util.converterStringParaMoeda(evt.target.value);
                            this.setState( { feria: evt.target.value}); 
                        }} />
                </div>
                <div className="col-sm-2">
                    <input type="text" className="form-control" id="despesaCartao" placeholder="Despesa" autoComplete="off" 
                        value={this.state.despesa}
                        onChange={ (evt) => { 
                            this.props.cartao.despesa = Util.converterStringParaMoeda(evt.target.value);
                            this.setState( { despesa: evt.target.value});
                        }} />
                </div>
                <div className="col-sm-2">
                    <button className="btn btn-outline-dark" id="excluirCartao" onClick={this.props.onExcluir}>-</button>
                </div>
            </div>
        )
    }
}