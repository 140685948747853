import $ from 'jquery';
import 'jquery-mask-plugin';

export default class Util {

    static formatarMoeda = (evt) => {

        console.log(evt);
        console.log(evt.target);
        $(evt.target).mask('#.###0,00', {reverse: true});
    };

    static converterStringParaMoeda = (valorEmString) => {

        if( typeof(valorEmString) !== 'string') return null;
        valorEmString = valorEmString.replace('.','').replace(',','.');
        let valorEmNumber = parseFloat( valorEmString);
        return valorEmNumber;
    }

    static formatarNumberEmStringMoeda = (valorNumber) => {

        if( isNaN(valorNumber) || typeof(valorNumber) !== 'number') return '';
        return valorNumber.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2});
    }

    static converterMesParaString = (indiceMes) => {

        const meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 
            'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
        return meses[indiceMes-1];
    }

    static converterDateParaString = (date) => {

        return date.getDate() + "/" + (date.getMonth()+1) + "/" + date.getFullYear();
    }

    static converterDateParaISOString = (date) => {

        return date.toISOString();
    }

    /**
     * Converte uma Date JS para string AAAA-MM-DD
     */
    static converterDateParaDataISOString = (date) => {

        return String(date.getFullYear()).padStart( 4, '0') + "-" + 
            String(date.getMonth() + 1).padStart( 2, '0') + "-" + 
            String(date.getDate()).padStart( 2, '0');
    }

    static obterCodigoTipoDespesaPai = (codigo) => {

        let partes = codigo.split('.');
        let montaCodigoPai='';
        for(let i=0; i<partes.length-1; i++) {
            
            montaCodigoPai += partes[i];
            if( i < partes.length - 2) {

                montaCodigoPai += '.';
            }
        }
        return montaCodigoPai;
    }
}