import React, { Component } from 'react';
import Util from '../../services/Util';
import * as yup from 'yup';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export default class FechamentoMes extends Component {

    constructor(props) {

        super(props);
        this.state = {
            carregando: true,
            ano: props.ano,
            mes: props.mes,
            movimentoFundoCaixaString: '0,00',
            saldoADividir: 0.0,
            fluxoMes: {
                feriaTotal: 0.0,
                mercadoria: 0.0,
                pessoal: 0.0,
                extra: 0.0,
                saldoFundoCaixaAnterior: 0.0,
                saldoFundoCaixa: 0.0,
                saldoTotal: 0.0
            }
        }
    }

    componentDidMount() {

        let anoMes = String(this.state.ano).padStart( 4, '0') + '-' + String(this.state.mes).padStart( 2, '0') + '-01';
        fetch('/api/fluxo-mes?mes=' + anoMes + '&api_token=' + localStorage.getItem('api_token'))
            .then( response => {
                console.log( response);
                if( response.status !== 200) {

                    throw new Error( response.statusText);
                }
                return response.json()
            })
            .then( fluxoMes => {

                console.log( fluxoMes);
                this.setState({
                    carregando: false,
                    fluxoMes,
                    movimentoFundoCaixaString: Util.formatarNumberEmStringMoeda( fluxoMes.saldoFundoCaixa-fluxoMes.saldoFundoCaixaAnterior)
                });
                this.atualizarSaldoADividir( fluxoMes.saldoFundoCaixaAnterior,fluxoMes.saldoFundoCaixa);
            })
            .catch(erro => {

                console.log( erro.message);
                if( erro.message === 'Unauthorized') {

                    console.log('erro de autenticacao');
                    this.props.login();
                } else {

                    this.props.onErro(`Erro ao obter 'fluxo-mes' da base de dados. ${erro}`);
                }
            });
    }

    atualizarSaldoADividir = (saldoFundoCaixaAnterior,saldoFundoCaixa) => {

        let diferencaSaldos = saldoFundoCaixa - saldoFundoCaixaAnterior;
        this.setState( { saldoADividir: this.state.fluxoMes.saldoTotal - diferencaSaldos });
    }

    onClick = (evt) => {

        evt.preventDefault();
        let fechamento_mes = {

            mes: String(this.state.ano).padStart( 4, '0') + '-' + String(this.state.mes).padStart( 2, '0') + '-01',
            saldoFundoCaixa: this.state.fluxoMes.saldoFundoCaixa
        }

        const schema = yup.object().shape({
            mes: yup.string().required().matches(/\d\d\d\d-\d\d-\d\d/),
            saldoFundoCaixa: yup.number().required().label('Valor saldo fundo de caixa incorreto')
        });

        schema.validate( fechamento_mes)
            .then((value) => {

                fetch('/api/fluxo-mes?api_token=' + localStorage.getItem('api_token'), { 
                    method: 'POST', 
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify( fechamento_mes) 
                }).then( (response) => {
        
                    console.log( `response do servidor`);
                    console.log( response);
                    if( response.status !== 200) {
        
                        throw new Error( response.statusText);
                    }
                    window.open( '/api/fluxo-mes/gerar-relatorio?mes=' + fechamento_mes.mes + '&api_token=' + localStorage.getItem('api_token'),'_blank');
                    this.props.onSelecaoDia();
            
                }).catch( (error) => {
                
                    this.props.onErro(`Erro ao inserir fluxo do mes. error=${error}`);
                });
            })
            .catch( (err) => {
                console.error( err);
                let msg = err.params.label || 'Erro nos dados digitados';
                confirmAlert({title: 'Erro', message: msg, buttons: [ {label: 'Ok'}] } );
            });
    }

    render() {

        return (

          <div className="container">
            <h1>{Util.converterMesParaString(this.state.mes)} de {this.state.ano}</h1>
            <br/>
            <div hidden={this.state.carregando}>
                
                <form>
                    <fieldset>
                        <div className="form-group row">
                            <label htmlFor="feria" className="col-sm-3 col-form-label">Féria Total</label>
                            <div className="col-sm-2">
                                <input type="text" className="form-control" id="feria" readOnly 
                                value={Util.formatarNumberEmStringMoeda(this.state.fluxoMes.feriaTotal)} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="mercadoria" className="col-sm-3 col-form-label">Mercadoria</label>
                            <div className="col-sm-2">
                                <input type="text" className="form-control" id="mercadoria" readOnly 
                                value={Util.formatarNumberEmStringMoeda(this.state.fluxoMes.mercadoria)} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="extra" className="col-sm-3 col-form-label">Extra</label>
                            <div className="col-sm-2">
                                <input type="text" className="form-control" id="extra" readOnly 
                                value={Util.formatarNumberEmStringMoeda(this.state.fluxoMes.extra)} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="extra" className="col-sm-3 col-form-label">Pessoal</label>
                            <div className="col-sm-2">
                                <input type="text" className="form-control" id="pessoal" readOnly 
                                    value={Util.formatarNumberEmStringMoeda(this.state.fluxoMes.pessoal)} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="saldoTotal" className="col-sm-3 col-form-label">Saldo Total</label>
                            <div className="col-sm-2">
                                <input type="text" className="form-control" id="saldoTotal" readOnly 
                                    value={Util.formatarNumberEmStringMoeda(this.state.fluxoMes.saldoTotal)} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="fundoCaixaAnterior" className="col-sm-3 col-form-label">Fundo Caixa Anterior</label>
                            <div className="col-sm-2">
                                <input type="text" className="form-control" id="fundoCaixaAnterior" readOnly 
                                    value={Util.formatarNumberEmStringMoeda(this.state.fluxoMes.saldoFundoCaixaAnterior)} />
                            </div>
                        </div>                        
                        <div className="form-group row">
                            <label htmlFor="movimentoFundoCaixa" className="col-sm-3 col-form-label">Movimento Fundo Caixa</label>
                            <div className="col-sm-2">
                                <input type="text" className="form-control" id="movimentoFundoCaixa" autoComplete="off"
                                    value={this.state.movimentoFundoCaixaString} 
                                    onChange={ evt => {
                                        
                                        let novoSaldo = this.state.fluxoMes.saldoFundoCaixaAnterior + 
                                            Util.converterStringParaMoeda(evt.target.value);
                                        this.setState({ 
                                            movimentoFundoCaixaString: evt.target.value,
                                            fluxoMes: {
                                                ...this.state.fluxoMes,
                                                saldoFundoCaixa: novoSaldo
                                            }
                                        });
                                        this.atualizarSaldoADividir(this.state.fluxoMes.saldoFundoCaixaAnterior,novoSaldo);
                                    }} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="fundoCaixaAtual" className="col-sm-3 col-form-label">Fundo Caixa Atual</label>
                            <div className="col-sm-2">
                                <input type="text" className="form-control" id="fundoCaixaAnterior" readOnly 
                                    value={Util.formatarNumberEmStringMoeda(this.state.fluxoMes.saldoFundoCaixa)} />
                            </div>
                        </div>                        
                        <div className="form-group row">
                            <label htmlFor="saldoADividir" className="col-sm-3 col-form-label">Saldo a Dividir</label>
                            <div className="col-sm-2">
                                <input type="text" className="form-control" id="saldoADividir" readOnly 
                                    value={Util.formatarNumberEmStringMoeda(this.state.saldoADividir)} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-2">
                                <button className="btn btn-primary" onClick={this.onClick}>Salvar</button>
                                <button className="btn btn-link" onClick={this.props.onSelecaoDia}>Voltar</button>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
            <div hidden={!this.state.carregando}>
                    <img src="progress.gif" alt="carregando..."/>
                </div>
           </div>
        );
    }



}