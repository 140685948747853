import React, { Component } from 'react';
import './login.css';

export default class Login extends Component {

    constructor() {

        super();
        this.state = {
            email: '',
            senha: '',
            msgErro: ''
        }
    }

    onClick = (evt) => {

        evt.preventDefault();

        fetch('/api/usuario/autenticar', { 
            method: 'POST', 
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify( { "email": this.state.email, "senha": this.state.senha}) 
          }).then( (response) => {
      
              console.log( `response do servidor`);
              console.log( response);

              switch( response.status) {
                  case 200:
                    return response.json();
                case 401:
                    this.setState( { msgErro: 'Dados incorretos!' });
                    return null;
                default:
                    throw new Error(`Servidor retornou status code inesperado = ${response.status}`);
              }
          }).then( (usuario) => {              

            console.group('usuario');
            console.log( usuario);
            console.groupEnd();
            if( usuario ) {

                localStorage.setItem( 'api_token', usuario.api_token);
                localStorage.setItem( 'nome', usuario.nome);
                this.props.onEntrar();
            }

          }).catch( (error) => {
            
              this.props.onErro(`Erro ao autenticar. error=${error}`);
          });
    }

    handleUserInput (evt) {

        const name = evt.target.name;
        const value = evt.target.value;
        this.setState({[name]: value, msgErro: ''});
    }

    render() {

        return (

            <div className="container">
                <div className="login-form">
                    <form method="post">
                        <h2 className="text-center">Identificação</h2>       
                        <div className="form-group">
                            <input tabIndex="0" type="text" name="email" className="form-control" placeholder="E-mail" required="required" 
                                onChange={(evt) => this.handleUserInput(evt)} />
                        </div>
                        <div className="form-group">
                            <input type="password" name="senha" className="form-control" placeholder="Senha" required="required" 
                                onChange={(evt) => this.handleUserInput(evt)}/>
                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn btn-primary btn-block" onClick={this.onClick}>Entrar</button>
                        </div>
                        <div className="clearfix">
                            <p>{this.state.msgErro}</p>
                        </div>
                        <div className="clearfix">
                            <label className="pull-left checkbox-inline"><input type="checkbox"/> Lembrar de mim</label>
                        </div>        
                    </form>
                </div>     
            </div>
        );
    }
}