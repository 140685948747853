import React, { Component, Fragment } from 'react'
import Creditado from '../Creditado'
import Select from 'react-select';
import Util from '../../services/Util';

export default class PagamentoCheque extends Component {

    constructor() {

        super();
        this.state = {
            optionsBanco: [],
            optionBanco: null,
            creditados: [],
            total: '0,00',
            cheque: ''
        }
    }

    componentDidMount() {

        let optionsBanco = this.props.menuBanco.map( banco => { return {value: banco.id, label: banco.nome} });
        let optionBanco = null;
        let cheque = '';
        if( this.props.pagamentoCheque.id) {

            optionBanco = optionsBanco.filter( item => item.value === this.props.pagamentoCheque.idBanco)[0];
            cheque = String(this.props.pagamentoCheque.cheque);
        }
        let valorTotal = 0.0;
        this.props.pagamentoCheque.creditados.forEach( creditado => {

            if( !isNaN( creditado.valor)) {
                valorTotal += creditado.valor;
            }
        });
        this.setState({

            optionsBanco,
            optionBanco,
            creditados: this.props.pagamentoCheque.creditados,
            cheque,
            total: Util.formatarNumberEmStringMoeda( valorTotal)
        });
    }

    excluirCreditado = (keyParaExcluir) => {

        console.log(`creditados = `);
        console.log( this.state.creditados);
        console.log( keyParaExcluir);

        let creditados = this.state.creditados.filter( (creditado) => creditado.key !== keyParaExcluir);
        this.setState({

            creditados
        });
        this.props.pagamentoCheque.creditados = creditados;
    };

    addCreditado = (evt) => {

        evt.preventDefault();
        const keyNovo = String(Math.max.apply(Math, this.props.pagamentoCheque.creditados.map(function(creditado) { return parseInt(creditado.key)+1; }))) | '0';
        const novoCreditado = {
            key: keyNovo,
            valor: 0.0
        };

        const creditadosAntigo = this.props.pagamentoCheque.creditados
        const novosCreditados = [ ...creditadosAntigo, novoCreditado];
        this.setState( {
            creditados: novosCreditados
        });
        this.props.pagamentoCheque.creditados = novosCreditados;
    }

    onChangeValorCreditado = () => {

        let valorTotal = 0.0;
        this.state.creditados.forEach( creditado => {

            console.log( creditado);

            if( !isNaN( creditado.valor)) {
                valorTotal += creditado.valor;
            }
        });
        this.setState({
            total: Util.formatarNumberEmStringMoeda(valorTotal)
        });
    };

    converterParaNumero = (str) => {

        console.log( str);
        if(!isNaN(str)) return 0.0;
        let trocado = str.replace(',','.');
        console.log(`trocado = ${trocado}`);
        return parseFloat( trocado);
    };

    render() {

        return (
            
            <Fragment>
                <div id="creditados">
                {
                    this.props.pagamentoCheque.creditados.map( (creditado) =>
                        <Creditado key={creditado.key}
                            creditado={creditado}
                            tiposDespesa={this.props.tiposDespesa}
                            adicionarTipoDespesa={this.props.adicionarTipoDespesa}
                            onErro={this.props.onErro} 
                            onExcluir={(event) => { 
                                event.preventDefault();
                                this.excluirCreditado(creditado.key);
                            }} 
                            onChangeValor={this.onChangeValorCreditado}/>                    
                    )
                }
                </div>
                <div className="form-group row">
                    <div className="col-sm-2">
                        <button className="btn btn-outline-dark" id="addCreditado" onClick={this.addCreditado}>+</button>
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-sm-2">&nbsp;</div>
                    <div className="col-sm-2">
                        <Select id="banco" placeholder="Banco"
                            options={this.state.optionsBanco} 
                            value={this.state.optionBanco}
                            onChange={(value) => {
                                this.props.pagamentoCheque.idBanco = value.value;
                                this.props.pagamentoCheque.nomeBanco = value.label;
                                this.setState({ optionBanco: value});
                            }} />
                    </div>
                    <div className="col-sm-2">
                        <input type="text" className="form-control" id="cheque" placeholder="Cheque"
                            value={this.state.cheque}
                            onChange={ (evt) => {
                                this.props.pagamentoCheque.cheque = evt.target.value;
                                this.setState( { cheque: evt.target.value });
                            }} />
                    </div>
                    <div className="col-sm-2">
                        <input type="text" className="form-control" id="valor" placeholder="Valor" 
                            value={this.state.total} 
                            readOnly />
                    </div>
                    <div className="col-sm-2">
                        <button className="btn btn-outline-dark" id="excluirDespesaCheque" onClick={this.props.onExcluir}>-</button>
                    </div>
                </div>
            </Fragment>
        )
    }
}